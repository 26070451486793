import React, { useEffect, useState } from 'react';
import { render } from "react-dom";
import './App.css';
import CompanySearch from './CompanySearch';
//import rows from './Companies'
import townHalls from './TownHallEvents'
//import 'bootstrap/dist/css/bootstrap.min.css';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import "../node_modules/react-add-to-calendar/dist/react-add-to-calendar.css";

import axios from 'axios';
import { readString } from 'react-papaparse'


function App() {

  const [isLoading, setLoading] = useState(true);
  const [data, setData] = useState();

  useEffect(() => {
    axios.get(process.env.PUBLIC_URL + "Startups.csv").then(res => {
      var json = readString(res.data, {skipEmptyLines: true, header: true});
      let rows = json.data;
      setData(json.data);
      setLoading(false);
    });
  }, []);

  if (isLoading) {
    return <div className="App">Loading...</div>;
  }

  return (
        <div className="App">
      <CompanySearch rows={data} townHalls={townHalls} />
    </div>
  );
}

export default App;
