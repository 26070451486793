import React from 'react';
import itemsjs from 'itemsjs';
import "react-bootstrap/dist/react-bootstrap.min.js"
import {Button, Accordion, Card} from 'react-bootstrap';

class CompanySearch extends React.Component {
  constructor(props) {
    super(props);

    let rows = props.rows;
    let industries = [];
    let deliveryRegions = [];
    let regionsList = {
      "Global": [
        {
          "name": "Europe",
          "list": [
            {
              "name": "France",
              "count": 0
            }, {
              "name": "Germany",
              "count": 0
            }, {
              "name": "UK",
              "count": 0
            }, {
              "name": "Ireland",
              "count": 0
            }, {
              "name": "Israel",
              "count": 0
            }, {
              "name": "Sweden",
              "count": 0
            }
          ],
          "count": 0
        }, {
          "name": "APAC",
          "list": [
            {
              "name": "Japan",
              "count": 0
            }
          ],
          "count": 0
        }, {
          "name": "AMER",
          "list": [
            {
              "name": "Canada",
              "count": 0
            }, {
              "name": "US",
              "count": 0
            }, {
              "name": "South America",
              "count": 0
            }
          ],
          "count": 0
        }, {
          "name": "MENA",
          "list": [
          ],
          "count": 0
        }
      ],
      "count": 0
    };
    let numberOfWhat = {};
    regionsList.Global.forEach((region, j) => {
      region.list.forEach((country, i) => {
        deliveryRegions.push(country.name)
      });
    });

    rows.forEach(item => {
      item.Industry.split(",").forEach((industry, i) => {
        if (industries.includes(industry)) {
          numberOfWhat[industry] += 1;
        } else {
          industries.push(industry);
          numberOfWhat[industry] = 1;
        }
      });
      item["Delivery regions"].split(",").forEach((region, i) => {
        switch (region) {
          case "Global":
            regionsList.count += 1;
            break;
          default:
          var index = regionsList.Global.findIndex(obj => obj.name==region);
          if(index != -1) {
            regionsList.Global[index].count += 1;
          } else {
            regionsList.Global.forEach((item, j) => {
              var index2 = item.list.findIndex(obj => obj.name==region);
              if(index2 != -1) {
                item.list[index2].count += 1 ;
              }
            });
          }
            break;

        }
      });

    });

    rows.forEach((item, i) => {
      item.Industry = item.Industry.split(",");
      item["Delivery regions"] = item["Delivery regions"].split(",");
    });
    this.state = {
      showSiteSummary: false,
      configuration: {
        searchableFields: ['Startup name'],
        sortings: {
          name_asc: {
            field: 'Startup name',
            order: 'asc'
          }
        },
        aggregations: {
          "Industry": {
            title: 'Industry',
            conjunction: false,
            size: 100
          },
          "Delivery regions": {
            title: 'Delivery Regions',
            conjunction: false,
            size: 100
          }
        }
      },
      industries: industries,
      deliveryRegions: deliveryRegions,
      numberOfWhat: numberOfWhat,
      regionsList: regionsList,
      row: rows
    }

    var newFilters = {};
    Object.keys(this.state.configuration.aggregations).map(function(v) {
      newFilters[v] = [];
    })

    // Copying this.state using the spread op (...this.state)
    this.state = {
      ...this.state,
      // the rows comes from external resources
      // https://github.com/itemsapi/itemsapi-example-data/blob/master/jsfiddle/imdb.js

      // In React line below is:
      //itemsjs: require('itemsjs')(rows, this.state.configuration),
      itemsjs: itemsjs(rows, this.state.configuration),

      query: '',
      filters: newFilters
    };
    this.toggleSiteDescription = this.toggleSiteDescription.bind(this);

  }

  changeQuery(e) {
    this.setState({query: e.target.value});
  }

  reset() {
    var newFilters = {};
    Object.keys(this.state.configuration.aggregations).map(function(v) {
      newFilters[v] = [];
    })
    this.setState({filters: newFilters, query: ''})
  }

  handleCheckbox = (filterName, filterValue) => event => {
    const oldFilters = this.state.filters;
    let newFilters = oldFilters;
    let check = event.target.checked;
    console.log(check);
    if(filterName === "Delivery regions") {
      let regionFilter;
      this.state.regionsList.Global.forEach((region, i) => {
        region.list.forEach((country, j) => {
          if(country.name === filterValue) {
            regionFilter = region.name;
          }
        });
      });
      if (check) {
        if(regionFilter) {
          document.getElementById(regionFilter).checked = true;
        }

        document.getElementById("Global").checked = true;
        newFilters[filterName].push(filterValue);
        if(newFilters[filterName].includes(regionFilter)){

        } else {
          newFilters[filterName].push(regionFilter);
        }
        if(newFilters[filterName].includes("Global")){

        } else {
          newFilters[filterName].push("Global");
        }
        this.setState({filters: newFilters})
      } else {
        let checkbox = Array.from(document.getElementsByClassName("country_" + regionFilter));
        let last = true;
        checkbox.forEach((item) => {
          if(item.checked) {
            return last = false;
          }
        });

        if(last) {
          if(regionFilter){
            document.getElementById(regionFilter).checked = false;
          }
          var index = newFilters[filterName].indexOf(regionFilter);
          if (index > -1) {
            newFilters[filterName].splice(index, 1);
            this.setState({filters: newFilters})
          }
        }

        let checkbox2 = Array.from(document.getElementsByClassName("deliveryRegions"));
        let theLast = true;
        checkbox2.forEach((item) => {
          if(item.checked) {
            return theLast = false;
          }
        });
        if(theLast) {
          document.getElementById("Global").checked = false;
          var index = newFilters[filterName].indexOf("Global");
          if (index > -1) {
            newFilters[filterName].splice(index, 1);
            this.setState({filters: newFilters})
          }
        }

        var index = newFilters[filterName].indexOf(filterValue);
        if (index > -1) {
          newFilters[filterName].splice(index, 1);
          this.setState({filters: newFilters})
        }
      }
    }
    else {
      if (check) {
        newFilters[filterName].push(filterValue);
        console.log(newFilters);
        this.setState({filters: newFilters})
      } else {
        var index = newFilters[filterName].indexOf(filterValue);
        if (index > -1) {
          newFilters[filterName].splice(index, 1);
          this.setState({filters: newFilters})
        }
      }
    }

  }

  get searchResult() {
    var result = this.state.itemsjs.search({per_page: 1000, query: this.state.query, filters: this.state.filters});
    result.data.aggregations.Industry.buckets = this.state.industries.sort();
    result.data.aggregations["Delivery regions"].buckets = this.state.deliveryRegions;
    return result
  }

  toggleSiteDescription() {
    this.setState(state => ({
      showSiteSummary: !state.showSiteSummary
    }));
  }

  render() {

    var siteDescToggledOnClass = this.state.showSiteSummary
      ? "whatThisIsCopy siteDescriptionToggledOn"
      : "whatThisIsCopy siteDescriptionToggledOff";
    var siteDescToggledOnCopy = this.state.showSiteSummary
      ? "Less about this site"
      : "More about this site";

    return (<div>
      <nav className="navbar navbar-default navbar-fixed-top">
        <div className="container">
          <div className="navbar-header">
            <a className="navbar-brand title" href="#" onClick={this.reset.bind(this)}><img className='awshclsLogo' src={process.env.PUBLIC_URL + 'AWS_logo_RGB.png'}/>
              Startup Connections - FR</a>
          </div>
          <div id="navbar">
            <form className="navbar-form navbar-left">
              <div className="form-group">
                <input type="text" value={this.state.query} onChange={this.changeQuery.bind(this)} className="form-control" placeholder="Search"/>
              </div>
            </form>
            <a className="siteDescriptionToggle" href='#' onClick={this.toggleSiteDescription}>
              {siteDescToggledOnCopy}</a>
          </div>
        </div>
      </nav>

      <div className="container">
        <div className={siteDescToggledOnClass}>
          Collaboration between startups and enterprises can no longer be viewed as an optional extra, it’s a strategic imperative. Startups are invaluable sources of innovation, fueling growth and providing pioneering business solutions. AWS Connections is an established program that facilitates relationships between AWS enterprise and startup customers to solve a need of the enterprise and, in turn, grow the startup. This site is an evolving effort to match inbound demand from large organizations with best-in-breed startup solutions. If you believe you have a relevant solution and wish us to evaluate for inclusion,
          <a href='https://airtable.com/shrRZtpc8z04hyb9Y' target="_blank">
            &nbsp;please let us know here.</a>
          <br/>
          <br/>
          You can’t find what you’re looking for in that list? Or you’re looking for an innovative solution outside France? <a href="https://pages.awscloud.com/aws-connections-intake-survey.html" target="_blank">Tell us what you’re looking for</a> and our team will find the right startup for you among thousands of AWS Startup customers.
          <br/>
          <small>*Note, we are looking to connect parties who will then evaluate one another for suitability and fit. Nothing herein should be considered an endorsement of any particular company or solution.</small>
        </div>
        <div className="row">
          <div className="col-4 col-md-3 facets">
            <div>
              <h5 style={{
                  marginBottom: '5px'
                }}>
                <strong style={{
                    color: '#FF9900'
                  }}>Industry</strong>
              </h5>
              <ul className="browse-list list-unstyled long-list" style={{
                  marginBottom: '0px'
                }}>
                {
                  this.state.industries.sort().map((item, i) => {
                    return (<li>
                      <div className="checkbox block" style={{
                          marginTop: '0px',
                          marginBottom: '0px'
                        }}>
                        <label>
                          <span><input className="checkbox" type="checkbox" onChange={this.handleCheckbox("Industry", item)}/>
                            &nbsp;{item} <small>({this.state.numberOfWhat[item]})</small></span>
                        </label>
                      </div>
                    </li>)
                  })
                }
              </ul>
            </div>
            <div>
              <h5 style={{
                  marginBottom: '5px'
                }}>
                <strong style={{
                    color: '#FF9900'
                  }}>Delivery Regions</strong>
              </h5>
              <Accordion defaultActiveKey="0">
                <ul className="browse-list list-unstyled long-list" style={{
                    marginBottom: '0px'
                  }}>
                  <Card>
                    <Accordion.Toggle as={Card.Header} eventKey="0">
                      <span><input className="checkbox" type="checkbox" id="Global" onChange={this.handleCheckbox("Delivery regions", "Global")}/>
                        &nbsp;Global <small>({this.state.regionsList.count})</small></span>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="0">
                      <div>
                        {
                          this.state.regionsList.Global.map((item, i) => {
                            return (<Card>
                              <Accordion.Toggle as={Card.Header} eventKey="O">
                                <span><input className="checkbox deliveryRegions" type="checkbox" id={item.name} onChange={this.handleCheckbox("Delivery regions", item.name)}/>
                                  &nbsp;{item.name} <small>({item.count})</small> </span>
                              </Accordion.Toggle>
                              <Accordion.Collapse eventKey="0">
                                <div className="countryList">
                                  {
                                    item.list.map((country, j) => {
                                      let className = "checkbox deliveryRegions country_"+ item.name;
                                      return (<div><input className={className} type="checkbox" onChange={this.handleCheckbox("Delivery regions", country.name)}/>
                                    &nbsp;{country.name}<small>&nbsp;({country.count})</small></div>)
                                    })
                                  }
                                </div>
                              </Accordion.Collapse>
                            </Card>)
                          })
                        }
                      </div>
                    </Accordion.Collapse>
                  </Card>

                </ul>
              </Accordion>
            </div>
          </div>
          <div className="col-8 col-md-9">
            <div className="breadcrumbs"></div>
            <div className="clearfix"></div>
            <div className="table table-striped">
              <div className="titleStartups">
                <h4 style={{
                    color: '#FF9900'
                  }}>Startups</h4>
                <small>Nombre de résultats : {this.searchResult.data.items.length}</small>
              </div>
              {
                Object.entries(this.searchResult.data.items).map(([key, item]) => {
                  var logoUrl = "";
                  if (item.Logo) {
                    logoUrl = item.Logo.slice(item.Logo.lastIndexOf('(') + 1).substring(0, item.Logo.slice(item.Logo.lastIndexOf('(') + 1).length - 1);
                  }

                  var contactString = "mailto:france-startups-enterprises-connections@amazon.com?subject=Introduction to " + item["Startup name"] + "&body=(Please include your name, company, and title)";
                  let url;

                  if (item["Website URL"].includes("https://")) {
                    url = item["Website URL"];
                  } else {
                    if (item["Website URL"].includes("www")) {
                      url = "https://" + item["Website URL"];
                    } else {
                      url = "https://wwww." + item["Website URL"];
                    }
                  }
                  return (<div className="companyRow" key={key}>
                    <div className='companyIcon'>
                      <img src={logoUrl}/>
                    </div>
                    <div className="companyDescriptionColumn">
                      <h5>
                        <b>
                          <a href={url} target='_blank'>{item["Startup name"]}</a>
                        </b>
                      </h5>
                      {
                        !!(item.Industry) && (item["Delivery regions"])
                          ? <div>
                              <sup>
                                <i>
                                  <b>Industry: </b>
                                  {item.Industry.join(", ")}<br/>
                                  <b>Delivery Regions: </b>
                                  {item["Delivery regions"].join(", ")}</i>
                              </sup>
                            </div>
                          : ""
                      }
                      {
                        !!(item.Description)
                          ? <div>{item.Description}</div>
                          : ""
                      }
                      {
                        !!(item["Existing customers"])
                          ? <div>
                              <b>Existing customers: </b>
                              {item["Existing customers"]}</div>
                          : ""
                      }
                      {
                        !!(item["Creation date"])
                          ? <div>
                              <b>Creation date: </b>
                              {item["Creation date"]}</div>
                          : ""
                      }
                      {
                        !!(item["What differentiates you from your competitors?"])
                          ? <div>
                              <b>What differentiates you from your competitors?: </b>
                              {item["What differentiates you from your competitors?"]}</div>
                          : ""
                      }
                      {
                        !!(item["AWS integration"])
                          ? <div>
                              <b>AWS integration: </b>
                              {item["AWS integration"]}</div>
                          : ""
                      }
                      <div className="emailColumn">
                        <Button className="emailButton" variant="primary" href={contactString}>Get Introduced to {item["Startup name"]}
                        </Button>
                      </div>
                    </div>

                  </div>)
                })
              }
            </div>
            <div className="clearfix"></div>
          </div>
        </div>
      </div>
    </div>)
  }
}

export default CompanySearch;
